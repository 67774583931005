import React from "react";
import firebase from "firebase";
import { orderBy, maxBy } from "lodash";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";

// Components
import ProjectDocumentTemplates from "../../components/project-templates/project-document-templates";
import SaveAsTemplateDialog from "./partials/document-save-as-template-dialog";
import AddSectionDialog from "./partials/document-add-section-dialog";
import RoleAccess from "../../components/role-access/role-access";
import CustomCard from "../../components/custom-card/custom-card";
import DocumentActionMenu from "./partials/document-action-menu";
import StateCard from "../../components/state-card/state-card";
import DocumentTemplates from "./partials/document-templates";
import Title from "../../components/title/title";

// Hooks
import useStoreProvider from "../../common/providers/store/use-app-context";
import useFetchCollection from "../../common/utils/use-fetch-collection";
import useFetchDocumentV1 from "../../common/utils/use-fetch-document";
import useFetchDocument from "../../common/utils/use-fetch-document-v2";

// Utilities and constants
import { copyDocument } from "../../common/utils/copy-document";
import { db, USER_ROLES, NOTIFICATION_POSITION } from "../../constants";
import messages from "../../i18n/messages";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    placeContent: "space-between",
    alignItems: "center",
  },
}));

const ProjectDocuments = () => {
  const intl = useIntl();
  const batch = db.batch();
  const { pid } = useParams();
  const classes = useStyles();
  const { getCollection } = useFetchCollection();
  const { getDocument } = useFetchDocumentV1();
  const {
    data: projectDetail,
    fetching: fetchingProjectDetail,
    getDocument: fetchProjectDetail,
  } = useFetchDocument();

  const { loggedInUser, selectedProject, setSelectedProject } = useStoreProvider();

  const [fetching, setFetching] = React.useState(true);
  const [documentSections, setDocumentSections] = React.useState([]);
  const [projectDocuments, setProjectDocuments] = React.useState([]);
  const [selectedDocumentTemplateId, setSelectedDocumentTemplateId] = React.useState(null);
  const [addSectionDailogState, setAddSectionDailogState] = React.useState(false);
  const [saveAsTemplateDialogState, setSaveAsTemplateDialogState] = React.useState(false);

  const skeletonArray = Array(5).fill("");
  const documentsCollectionRef = db
    .collection("projects")
    .doc(pid)
    .collection("documents")
    .orderBy("createdAt", "desc")
    .where("status", "==", "active");

  const breadcrumbsNav = [
    { label: "Projects", to: "/" },
    { label: selectedProject?.projectName, to: `/projects/${pid}` },
    { label: "Documents", to: `/projects/${pid}/weld-logs/documents` },
  ];

  // Delete document section. When a document section is deleted, it will be excluded
  // from report generation since it will not be included in the section selection
  const handleDocumentSectionDelete = async (sectionKey, sectionId) => {
    const documentTemplateId = selectedProject?.documentTemplate;
    const sectionsDocumentRef = db
      .collection("projects")
      .doc(pid)
      .collection("document-sections")
      .doc(documentTemplateId);

    const deletedSectionDocumentsRef = db
      .collection("projects")
      .doc(pid)
      .collection("documents")
      .where("section", "==", sectionId);

    try {
      await sectionsDocumentRef.set(
        {
          documentSections: {
            [sectionKey]: firebase.firestore.FieldValue.delete(),
          },
        },
        { merge: true }
      );

      const updatedDocumentSections = await getDocument(sectionsDocumentRef);
      setDocumentSections(updatedDocumentSections);

      // Delete documents that were under the deleted sections
      const documentsOfDeletedSection = await getCollection(deletedSectionDocumentsRef);

      documentsOfDeletedSection.docs?.map(async (doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      toast.success(messages.documentSectionDeleted, {
        position: NOTIFICATION_POSITION,
      });
    } catch (error) {
      console.log("handleDocumentSectionDelete::", error);
    }
  };

  const getSelectedDocumentTemplate = async () => {
    /* 
      TemplateId of current project. It could be fetched in 3 ways
        1. From selection menu if the project document section is being created
        2. From selectedProject object saved on context
        3. On refresh, project details is fetched and templateId is updated
    */
    const documentTemplateId =
      selectedDocumentTemplateId ||
      selectedProject?.documentTemplate ||
      projectDetail?.documentTemplate;

    if (!documentTemplateId) {
      setDocumentSections(null);
      setFetching(false);
      return;
    }

    const document = await db
      .collection("projects")
      .doc(pid)
      .collection("document-sections")
      .doc(documentTemplateId)
      .get();

    const templateDocument = document.data();
    setDocumentSections(templateDocument);
    setFetching(false);
  };

  // Update selectedProject object with templateId
  const updateSelectedProjectDetails = async (templateId) => {
    const updatedProject = {
      ...selectedProject,
      documentTemplate: templateId,
    };
    setSelectedProject(updatedProject);
  };

  // When a project is created and document template is chosen, project
  // is updated with the type of document template that is taken into use.
  const updateProjectWithSelectedTemplateId = async () => {
    await db.collection("projects").doc(pid).set(
      {
        documentTemplate: selectedDocumentTemplateId,
      },
      { merge: true }
    );
    await updateSelectedProjectDetails(selectedDocumentTemplateId);
  };

  // Copy template document from project-templates to document-sections
  // of a project.
  const addSelectedDocumentTemplateToProject = async () => {
    const fromDocument = db
      .collection("project-templates")
      .doc("customs")
      .collection("english")
      .doc(selectedDocumentTemplateId);

    const toDocument = db
      .collection("projects")
      .doc(pid)
      .collection("document-sections")
      .doc(selectedDocumentTemplateId);

    await copyDocument(fromDocument, toDocument);
    await updateProjectWithSelectedTemplateId();
    await getSelectedDocumentTemplate();
  };

  const getProjectDocuments = (querySnapshot) => {
    const projectDoc = querySnapshot?.docs?.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const orderedDocuments = orderBy(projectDoc, ["createdAt"], ["desc"]);
    setProjectDocuments(orderedDocuments);
  };

  const addHeaderToTemplate = async (headerName) => {
    if (!headerName) return;

    const sections = documentSections?.documentSections || {};
    const existingHeaders = Object.values(sections);

    const maxPosition = maxBy(existingHeaders, "position")?.position || 0;
    const position = maxPosition + 1;
    const key = `custom-header-${position}`;

    try {
      await db
        .collection("projects")
        .doc(pid)
        .collection("document-sections")
        .doc(selectedProject?.documentTemplate)
        .set(
          {
            documentSections: {
              [key]: { name: headerName, id: position, position: position },
            },
          },
          { merge: true }
        );

      // Show success toast
      toast.success(messages.documentSectionAdded, {
        position: NOTIFICATION_POSITION,
      });

      // Refresh the document template
      getSelectedDocumentTemplate();
    } catch (error) {
      console.error("Error adding header:", error);
    }
  };

  const saveAsTemplate = (templateName) => {
    if (!templateName) return;

    const templateDocumentId = db
      .collection("projects")
      .doc(pid)
      .collection("document-sections")
      .doc().id;

    db.collection("project-templates")
      .doc("customs")
      .collection("english")
      .doc(templateDocumentId)
      .set({
        ...documentSections,
        id: templateDocumentId,
        templateName: templateName,
        createdBy: `${loggedInUser?.fname} ${loggedInUser?.lname}`,
        createdByUid: loggedInUser?.uid,
        createdAt: firebase.firestore.Timestamp.now(),
      });
  };

  const handleClickAddSectionItem = () => {
    setAddSectionDailogState(true);
  };

  const handleAddSection = async (value) => {
    setAddSectionDailogState(false);
    await addHeaderToTemplate(value);
  };

  const handleSaveAsSectionItemClick = () => {
    setSaveAsTemplateDialogState(true);
  };

  const handleSaveAsTemplate = (value) => {
    setSaveAsTemplateDialogState(false);
    saveAsTemplate(value);
  };

  const renderEmptyStateCard = () => {
    const buttonProps = {
      color: "primary",
      variant: "contained",
      label: intl.formatMessage(messages.useTemplate),
      onClick: () => {
        addSelectedDocumentTemplateToProject();
      },
      disabled: !Boolean(selectedDocumentTemplateId),
    };

    return (
      <StateCard
        title={intl.formatMessage(messages.noDocumentsYet)}
        description={intl.formatMessage(messages.pleaseSelectTheDocument)}
        buttonProps={buttonProps}
        children={
          <DocumentTemplates
            selectedTemplate={selectedDocumentTemplateId}
            setSelectedDocumentTemplateId={setSelectedDocumentTemplateId}
          />
        }
      />
    );
  };

  React.useEffect(() => {
    getSelectedDocumentTemplate();
    documentsCollectionRef.onSnapshot(getProjectDocuments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail]);

  // Fetch project details only if empty
  React.useEffect(() => {
    if (!selectedProject) {
      const projectDocumentRef = db.collection("projects").doc(pid);
      fetchProjectDetail(projectDocumentRef);
      setSelectedProject(projectDetail);
    }
  }, [fetchProjectDetail, projectDetail, pid, selectedProject, setSelectedProject]);

  return (
    <>
      <Box width="100%">
        {documentSections ? (
          <>
            <Title
              title={intl.formatMessage(messages.documents)}
              breadcrumbsNav={breadcrumbsNav}
              fetchingProjectDetail={fetchingProjectDetail}
            />
            <CustomCard
              header={
                <Box className={classes.header}>
                  <Typography variant="subtitle1">{documentSections?.templateName}</Typography>
                  <RoleAccess
                    roles={[
                      USER_ROLES.ADMIN,
                      USER_ROLES.PROJECT_LEADER,
                      USER_ROLES.WELDING_COORDINATOR,
                      USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
                    ]}
                  >
                    <DocumentActionMenu
                      handleClickAddSectionItem={handleClickAddSectionItem}
                      handleSaveAsSectionItemClick={handleSaveAsSectionItemClick}
                    />
                  </RoleAccess>
                </Box>
              }
            >
              <Box>
                {fetching
                  ? skeletonArray?.map((_value, index) => (
                      <Skeleton animation="wave" width="100%" height={50} key={index} />
                    ))
                  : Object.keys(documentSections?.documentSections)
                      .sort(
                        (a, b) =>
                          documentSections?.documentSections[a].position -
                          documentSections?.documentSections[b].position
                      )
                      .map((sectionKey, index) => {
                        const docForSection = projectDocuments.filter((doc) => {
                          return doc.section === documentSections?.documentSections[sectionKey].id;
                        });
                        return (
                          <ProjectDocumentTemplates
                            key={index}
                            section={documentSections?.documentSections[sectionKey]}
                            sectionKey={sectionKey}
                            sectionNumber={index + 1}
                            docForSection={docForSection}
                            sectionId={documentSections?.documentSections[sectionKey].id}
                            handleDocumentSectionDelete={handleDocumentSectionDelete}
                          />
                        );
                      })}
              </Box>
              <Box mt={4}>
                <RoleAccess
                  roles={[
                    USER_ROLES.ADMIN,
                    USER_ROLES.PROJECT_LEADER,
                    USER_ROLES.WELDING_COORDINATOR,
                    USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
                  ]}
                >
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    onClick={() => handleClickAddSectionItem()}
                  >
                    {intl.formatMessage(messages.addSection)}
                  </Button>
                </RoleAccess>
              </Box>
            </CustomCard>
            <AddSectionDialog open={addSectionDailogState} onClose={handleAddSection} />

            <SaveAsTemplateDialog open={saveAsTemplateDialogState} onClose={handleSaveAsTemplate} />
          </>
        ) : (
          renderEmptyStateCard()
        )}
      </Box>
    </>
  );
};

ProjectDocuments.propTypes = {};

export default ProjectDocuments;
